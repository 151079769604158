import { StaticImage } from 'gatsby-plugin-image'
import { React, CenterWrapper, View, SEO, Page, StyleSheet, Button, Link, Text, Image } from 'lib'
import { Theme, HTMLStylesDark } from 'app'
import { BellOffIcon, MapPinOffIcon, PhoneOffIcon } from 'assets/icons'
import Screenshot from '../../assets/screenshot4.png'
import { PageTitle } from 'components'

const header = 'What is Becon, and what do I have to do as a Key Contact?'

const items = [
  {
    icon: <PhoneOffIcon size={64} color={Theme.colors.grad1}/>,
    text: 'You do not need to download Becon to receive emergency alerts.',
  },
  {
    icon: <MapPinOffIcon size={64} color={Theme.colors.grad1}/>,
    text: 'You aren’t required to monitor or track the user’s live location',
  },
  {
    icon: <BellOffIcon size={64} color={Theme.colors.grad1}/>,
    text: 'You won’t be pinged with notifications every time they use the app.',
  },
]


function KeyContactsPage(props) {
  return (
    <Page whiteBackground>
      <SEO doNotTrack title={'Key Contacts'} description={header}/>
      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.content} textWrapper>
        <PageTitle location='Guidance' title={header}/>
        <Text variant={'p2 inline marginTop:3'}>Becon is a smart safety app which adds a digital layer of protection to different journeys and activities, like walking, running, cycling and more.</Text>
        <Text variant={'p2 inline marginTop:3'}>When activated, Becon monitors its user’s device until they safely reach their destination or end their activity.</Text>
        <Text variant={'p2 inline marginTop:3'}>If something unusual is detected during safety monitoring, Becon will automatically check in on the user with a timed notification to make sure they’re ok.</Text>
        <Text variant={'p2 inline marginTop:3'}>If the user is unable to confirm their safety by the end of the timer, Becon will send alerts to their pre-selected Key Contacts (that’s you). </Text>
        <Text variant={'p2 inline marginTop:3'}>A user may also initiate Becon to send alerts manually if they need and are able to.</Text>
        <View variant={'marginVertical:4'}><Button variant={'gradient uppercase responsive noDecoration white'} text={'Learn more about Becon’s Safety Triggers'} to={'/guidance/triggers'}/></View>
        <Text variant={'h4 bold marginBottom:3'}>
        As a Key Contact You Don’t Need To Do Much At All...
        </Text>
        <View style={styles.iconsContainer} variant={'marginTop:5'}>
          {items.map(({ icon, text }, key) => (
            <View key={key} style={styles.iconWrapper}>
              {icon}
              <Text variant={`p2 marginTop:3`} text={text}/>
            </View>
          ))}
        </View>
        <View style={styles.separator} variant={'marginTop:10 marginBottom:8'}/>
        <Text variant={'h4 bold marginBottom:3'}>
          You will only be alerted if:
        </Text>
        <Text variant={'p1 inline'}>The user is unable to respond to confirm their safety following a timed check-in notification from <strong>Becon.</strong></Text>
        <Text variant={'h4 bold marginVertical:3'}>
          OR
        </Text>
        <Text variant={'p1 inline'}>The user manually initiates Becon to send emergency alerts by pushing a button in the app.</Text>
        <View style={styles.screenshotWrapper}>
          <Image source={Screenshot} style={{ ...styles.image }}/>
          <View variant={'flex column'} style={styles.list}>
            <Text variant={'p1 bold'}>Alerts Are Sent Via SMS And Contain:</Text>
            <Text variant={'p2'} style={{ marginTop: Theme.spacing(3) }}>1. The reason for the alert.</Text>
            <Text variant={'p2'} style={{ marginTop: Theme.spacing(3) }}>2. A link to Becon's emergency tracking platform.</Text>
          </View>
        </View>
      </CenterWrapper>
    </Page>
  )
}


const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    // minHeight: '100vh',
    ...Theme.center,
    ...Theme.flex,
    flexDirection: 'column',
    marginTop: Theme.spacing(20),
    marginBottom: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(12),
      marginBottom: Theme.spacing(1),
    },
  },
  content: {
    ...HTMLStylesDark.plainText,
    display: 'flex',
    width: 'auto',
    maxWidth: Theme.values.maxTextContentWidth,
    flexDirection: 'column',
  },
  iconsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: Theme.spacing(5),

    [Theme.media.down('small')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  iconWrapper: {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifySelf: 'center',
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: Theme.colors.greyscale4,
  },
  screenshotWrapper: {
    width: '100%',
    marginTop: Theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    gap: Theme.spacing(6),

    [Theme.media.down('small')]: {
      marginTop: Theme.spacing(6),
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  list: {
    maxWidth: 370,
    alignSelf: 'flex-start',
  },
  image: {
    maxWidth: 470,
    maxHeight: 750,
    height: '100%',
    width: '45vw',
    [Theme.media.down('small')]: {
      width: '80vw',
    },
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    // maxHeight: 800,
  },
})

export default KeyContactsPage
